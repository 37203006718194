// Components
import React from "react";
import Auth from "../components/Auth";
// Styles
import "./AuthPage.css";

const AuthPage = () => {
    return (
        <Auth/>
    );
}

export default AuthPage;